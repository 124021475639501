import {useState, useRef, useEffect} from 'react'
import {Weather,Observation} from './weather'
import Point from './point'
import Fetcher from './fetcher'

// WeatherClient fetches the weather data from the server and creates a weather object
// for use by the map (WeatherManager)
export default function WeatherClient({setWeather, weatherURL, tracker}) {
  const myWeatherRaw = useRef()
  const [weatherRaw, setWeatherRaw] = useState()
  const [triggerUpdateWeatherURL, setTriggerUpdateWeatherURL] = useState()
  const trackerCoords = useRef()

  useEffect(() => {

    if (setWeather && weatherRaw && weatherRaw.station && weatherRaw !== myWeatherRaw.current) {
      myWeatherRaw.current = weatherRaw

      // Create Weather Obj
      let point = new Point(
        weatherRaw.station.geometry.coordinates[0],
        weatherRaw.station.geometry.coordinates[1]
        )
      let observation = new Observation()
      observation.timestamp = new Date(Date.parse(weatherRaw.timestamp))
      observation.textDescription = weatherRaw.observation.textDescription
      observation.temperatureC = weatherRaw.observation.temperature.value
      observation.windDirectionDeg = weatherRaw.observation.windDirection.value
      observation.windSpeedKPH = weatherRaw.observation.windSpeed.value
      observation.windGustKPH = weatherRaw.observation.windGust.value
      observation.relativeHumidityPct = weatherRaw.observation.relativeHumidity.value
      observation.windChillC = weatherRaw.observation.windChill.value
      observation.heatIndexC = weatherRaw.observation.heatIndex.value
      
      let weather = new Weather(
        weatherRaw.station.properties.name,
        weatherRaw.station.properties.stationIdentifier,
        point,
        observation,
        weatherRaw.forecastLink
      )
      
      setWeather(weather)
    }
  },[weatherRaw, setWeather])

  useEffect(() => {

    let newCoords = tracker?.lastPoint?.getCoords()
    if (newCoords && newCoords !== trackerCoords.current) {
      trackerCoords.current = newCoords
      setTriggerUpdateWeatherURL(`${weatherURL}?coords=${newCoords}`)
    }

  },[setTriggerUpdateWeatherURL, tracker, weatherURL, trackerCoords])

  return <Fetcher url={triggerUpdateWeatherURL} updateTarget={setWeatherRaw} />

}