import { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl'
import * as turf from '@turf/turf'

export default function TrackerManager({ route, tracker, startTime, setTrackerIndex, units, map }) {
  const popup = useRef(new mapboxgl.Popup())
  const markerDiv = useRef(document.createElement("div"))
  markerDiv.current.id="tracker-marker"
  const markerSVG = '<svg fill="currentColor" width="24px" height="24px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" transform="rotate(-45)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M25.497 6.503l.001-.003-.004.005L3.5 15.901l11.112 1.489 1.487 11.11 9.396-21.992.005-.006z"></path></g></svg>'
  markerDiv.current.innerHTML = markerSVG
  markerDiv.current.style = "color: #00f"
  const marker = useRef(new mapboxgl.Marker({
    element:markerDiv.current,
    pitchAlignment:"map",
    rotationAlignment:"map"
  }
  ).setPopup(popup.current))

  const trackerLineSourceRef = useRef(null)
  const trackerLineLayerRef = useRef(null)
  const initialized = useRef(false)

  useEffect(() => {
    const getPopupText = (name, updated, firstClosestPoint, currentClosestPoint, lastPoint, firstPoint, total, nextWaypoint, startTime) => {
      let sOnRoute
      let routeAvgSpeed
      if (startTime) {
        sOnRoute = (new Date(Date.parse(lastPoint.timestamp)) - new Date(Date.parse(startTime))) / 1000
        routeAvgSpeed = units.getDistance(currentClosestPoint.dist) / (sOnRoute / 3600)
      }
      let secondsTracked
      let trackedAverageSpeed
      if (firstClosestPoint) {
        // get time between points
        secondsTracked = (new Date(Date.parse(lastPoint.timestamp)) - new Date(Date.parse(firstPoint.timestamp))) / 1000
        // get distance between points`
        let metersTracked = currentClosestPoint.dist - firstClosestPoint.dist
        trackedAverageSpeed = units.getDistance(metersTracked) / (secondsTracked / 3600)
      }

      var data = new Map([
        ["Timestamp", (lastPoint.timestamp ? lastPoint.timestamp.toLocaleString() : "")],
        ["Route Distance", units.getDistance(currentClosestPoint.dist).toFixed(2)],
        ["Remaining", units.getDistance((total - currentClosestPoint.dist)).toFixed(2)],
        ["Speed", `${units.getDistance(lastPoint.velocity).toFixed(1)} ${units.getSpeedName()}`],
      ])
      if (lastPoint.course) {
        data.set("Heading", `${degreesToCompass(lastPoint.course)} ${lastPoint.course}˚`)
      }
      if (nextWaypoint) {
        const waypointDist = units.getDistance(nextWaypoint.dist - currentClosestPoint.dist)
        data.set(`Distance to ${nextWaypoint.name}`, waypointDist.toFixed(2))
        if (firstClosestPoint) {
          data.set(`ETA to ${nextWaypoint.name}`, secondsToDuration((waypointDist / trackedAverageSpeed) * 3600))
        } else if (startTime) {
          data.set(`ETA to ${nextWaypoint.name}`, secondsToDuration((waypointDist / routeAvgSpeed) * 3600))
        }
      }
      if (updated) {
        data.set("Updated", updated.toLocaleString())
      }

      if (firstClosestPoint) {
        data.set(`${secondsToDuration(secondsTracked)} avg Speed`, `${trackedAverageSpeed.toFixed(2)} ${units.getSpeedName()}`)
        data.set("Est ETA", secondsToDuration((units.getDistance(total - currentClosestPoint.dist) / trackedAverageSpeed) * 3600))
      }

      if (startTime) {
        data.set("Route avg Speed", `${routeAvgSpeed.toFixed(2)} ${units.getSpeedName()}`)
        data.set("Time on Route", secondsToDuration(sOnRoute))
        data.set("Route avg Est ETA", secondsToDuration((units.getDistance(total - currentClosestPoint.dist) / routeAvgSpeed) * 3600))
      }
      const keys = data.keys()
      let table = document.createElement("table")
      for (let key of keys) {
        let row = document.createElement("tr")
        let name = document.createElement("td")
        let value = document.createElement("td")
        name.appendChild(document.createTextNode(key))
        value.appendChild(document.createTextNode(data.get(key)))
        row.appendChild(name)
        row.appendChild(value)
        table.appendChild(row)
      }
      return `<div id="tracker-popup">
        <strong>${name}</strong><br/>
        ${table.outerHTML}
        </div>`
    }

    const secondsToDuration = (seconds) => {
      seconds = seconds.toFixed(0)
      let s = seconds % 60
      seconds -= s
      let m = Math.floor((seconds % 3600) / 60)
      seconds -= m
      let h = Math.floor((seconds % (60 * 60 * 24)) / 3600)
      seconds -= h
      let d = Math.floor(seconds / (60 * 60 * 24))

      let duration = ''
      if (d > 0) {
        d = String(d).padStart(1, '0')
        duration = `${d}d`
      }
      if (h > 0 || duration.length > 0) {
        h = String(h).padStart(2, '0')
        duration = `${duration} ${h}h`
      }
      if (m > 0 || duration.length > 0) {
        m = String(m).padStart(2, '0')
        duration = `${duration} ${m}m`
      }

      return duration
    }

    const degreesToCompass = (degrees) => {
      const compasPoints = ["N", "NE", "E", "SE", "S", "SW", "W", "NW", "N"]
      return compasPoints[(Math.round(degrees / 45))]
    }

    const getClosest = (route, point) => {
      const origin = turf.point(point.getCoords())
      const line = turf.lineString(route.getCoords())
      const match = turf.nearestPointOnLine(line, origin)
      return match.properties.index
    }

    const getNextWaypoint = (waypoints, trackerIndex) => {
      if (waypoints) {
        for (let waypoint of waypoints) {
          if (waypoint.routeIndex > trackerIndex) {
            return waypoint
          }
        }
      }
    }

    const getCoords = (points) => {
      let coords = []
      points.forEach(point => {
        coords.push([point.lon, point.lat])
      })
      return coords
    }

    const getGeoJSON = (tracker) => {
      let coords = []
      if (tracker.path.length > 1) {
        coords = tracker.path.getCoords()
      }
      let geojson = {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'LineString',
            'coordinates': coords
          }
        }
      }
      return geojson
    }

    // Effect
    if (route &&
      route.length > 1 &&
      tracker &&
      tracker.lastPoint &&
      tracker.lastPoint.getCoords() &&
      tracker.name
    ) {
      let currentClosestIndex = getClosest(route, tracker.lastPoint)
      const currentClosestPoint = route.getPoint(currentClosestIndex)
      let nextWaypoint = getNextWaypoint(route.waypoints, currentClosestIndex)

      let firstClosestPoint
      if (tracker.firstPoint) {
        let firstClosestIndex = getClosest(route, tracker.firstPoint)
        firstClosestPoint = route.getPoint(firstClosestIndex)
      }

      marker.current.setLngLat(tracker.lastPoint.getCoords())
      marker.current.setRotation(tracker.lastPoint.course)

      popup.current.setHTML(getPopupText(
        tracker.name,
        tracker.updated,
        firstClosestPoint,
        currentClosestPoint,
        tracker.lastPoint,
        tracker.firstPoint,
        route.getPoint(route.length - 1).dist,
        nextWaypoint,
        startTime
      ))
      setTrackerIndex(currentClosestIndex)
    }

    if (tracker && tracker.path && tracker.path.length > 0) {
      if (initialized.current === false) {
        map.on('load', () => {
          if (!map.getSource('tracker-line')) {
            trackerLineSourceRef.current = map.addSource('tracker-line', getGeoJSON(tracker))
          }
          if (!map.getLayer('tracker-line')) {
            trackerLineLayerRef.current = map.addLayer({
              'id': 'tracker-line',
              'type': 'line',
              'source': 'tracker-line',
              'layout': {
                'line-join': 'round',
                'line-cap': 'round'
              },
              'paint': {
                'line-color': "#f00",
                'line-width': 6
              }
            }) 
          }
          if (map.getSource('tracker-line') && map.getLayer('tracker-line')) {
            initialized.current = true
          }
        })
      } else {
        map.getSource('tracker-line').setData(getGeoJSON(tracker).data)
      }
    }

    if (marker.current.getLngLat()) {
      marker.current.addTo(map)
    } else {
      marker.current.remove()
    }

  }, [route, tracker, startTime, setTrackerIndex, units, map])


  return <></>
}