import { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl'

export default function WeatherManager({ weather, units, map }) {
  const popup = useRef(new mapboxgl.Popup())
  const markerDiv = useRef(document.createElement("div"))
  markerDiv.current.id="weather-marker"
  const markerSVG = '<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="24" height="24" viewBox="-1 -2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.2429 5.75708C18.586 8.10023 18.586 11.8992 16.2429 14.2424M7.75758 14.2424C5.41443 11.8992 5.41443 8.10023 7.75758 5.75708M4.92893 17.0708C1.02369 13.1656 1.02369 6.83395 4.92893 2.92871M19.0715 2.92871C22.9768 6.83395 22.9768 13.1656 19.0715 17.0708M12.0002 11.9998C13.1048 11.9998 14.0002 11.1043 14.0002 9.99976C14.0002 8.89519 13.1048 7.99976 12.0002 7.99976C10.8957 7.99976 10.0002 8.89519 10.0002 9.99976C10.0002 11.1043 10.8957 11.9998 12.0002 11.9998ZM12.0002 11.9998V20.9998" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
  markerDiv.current.innerHTML = markerSVG
  markerDiv.current.style = "color: #000"
  const marker = useRef(new mapboxgl.Marker({
    element:markerDiv.current,
  }
  ).setPopup(popup.current))

  useEffect(() => {
    const getPopupText = (weather) => {

      let temp = `${units.getTemperature(weather.observation.temperatureC).toFixed(1)} ${units.getTemperatureName()}`
      let windDirection = degreesToCompass(weather.observation.windDirectionDeg)
      let windSpeed = `${units.getDistance(weather.observation.windSpeedKPH).toFixed(1)} ${units.getSpeedName()}` 
      let windGust = weather.observation.windGustKPH ? `(${units.getDistance(weather.observation.windGustKPH).toFixed(1)})` : ""
      let windChill = weather.observation.windChillC ? `${units.getTemperature(weather.observation.windChillC).toFixed(1)} ${units.getTemperatureName()}` : null
      let heatIndex = weather.observation.heatIndexC ? `${units.getTemperature(weather.observation.heatIndexC).toFixed(1)} ${units.getTemperatureName()}` : null

      var data = new Map([
        ["Updated", weather.observation.timestamp.toLocaleString()],
        ["Conditions", weather.observation.textDescription],
        ["Temperature", `${temp}`],
        ["Wind", `${windSpeed} ${windGust} ${windDirection}`],
        ["RH", `${weather.observation.relativeHumidityPct.toFixed(0)}%`],
      ])

      if (windChill) {
        data.set("Wind Chill", `${windChill}`)
      }

      if (heatIndex) {
        data.set("Heat Index", `${heatIndex}`)
      }
      
      const keys = data.keys()
      let table = document.createElement("table")
      for (let key of keys) {
        let row = document.createElement("tr")
        let name = document.createElement("td")
        let value = document.createElement("td")
        name.appendChild(document.createTextNode(key))
        value.appendChild(document.createTextNode(data.get(key)))
        row.appendChild(name)
        row.appendChild(value)
        table.appendChild(row)
      }
      return `<div id="weather-popup">
        <strong><a target="_new" href="${weather.forecastLink}">${weather.name}</a></strong><br/>
        ${table.outerHTML}
        </div>`
    }

    const degreesToCompass = (degrees) => {
      const compasPoints = ["↓ N", "↙ NE", "← E", "↖ SE", "↑ S", "↗ SW", "→ W", "↘ NW", "↓ N"]
      return compasPoints[(Math.round(degrees / 45))]
    }

    // Effect
    if (weather &&
      weather.point &&
      weather.point.getCoords()
    ) {
      marker.current.setLngLat(weather.point.getCoords())

      popup.current.setHTML(getPopupText(weather))
    }

    if (marker.current.getLngLat()) {
      marker.current.addTo(map)
    } else {
      marker.current.remove()
    }

  }, [weather, map])


  return <></>
}