import Point from './point'
import Path from './path'

export default class Route {
  path
  waypoints
  bounds
  name

  constructor() {
    this.path = new Path()
    this.path.points[0] = new Point(0,0,0)
    this.path.points[0].dist = 0
    this.name = "waiting..."
    this.waypoints = []
  }

  getCoords() {
    return this.path.getCoords()
  }

  get length() {
    return this.path.length
  }

  getPoint(index) {
    return this.path.getPoint(index)
  }

  getGeoJSON() {
    let geojson = {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'LineString',
          'coordinates': this.getCoords()
        }
      }
    }
    return geojson
  }

  getRouteFeatureList() {
    return this.getFeatureList(this.path.points)
  }

  getWaypointsFeatureList() {
    return this.getFeatureList(this.waypoints)
  }

  getFeatureList(points) {
    let featureList = []
    for (let point of points) {
      let feature = {
        'properties': {
          'elevation': point.ele,
          'distance': point.dist,
        },
        'geometry': {
          'type': 'Point',
          'coordinates': [
            point.lon,
            point.lat
          ]
        },
        'id': featureList.length
      }
      featureList.push(feature)
    }
    return featureList
  }

  getFeatureGeoJson(featureList) {
    return {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': featureList
      }
    }
  }

  getWaypointsGeoJson() { }

  setBounds() {
    this.bounds = this.path.getBounds()
  }

  getBounds() {
    if (!this.bounds) {
      this.setBounds()
    }
    return this.bounds
  }
}