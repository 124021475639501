import {useState, useRef, useEffect} from 'react'
import Tracker from './tracker'
import Point from './point'
import Path from './path'
import Fetcher from './fetcher'

// TrackerClient fetches the tracker data from the server and creates a tracker object
// for use by the chart (Chart) and map (TrackerManager)
export default function TrackerClient({setTracker, trackerURL}) {
  const myTrackerRaw = useRef()
  const trackerInterval = useRef(6000)
  const [trackerRaw, setTrackerRaw] = useState()

  useEffect(() => {

    let randomize = false
      if (randomize) {
        setInterval(() => {
          generateRandomData()
        }, 8000)
      }

    if (setTracker && trackerRaw && (trackerRaw !== myTrackerRaw.current) && trackerRaw.Path) {
      myTrackerRaw.current = trackerRaw

      let name = trackerRaw.Name
      let updated = new Date(Date.parse(trackerRaw?.Updated))
      let path = new Path()

      trackerRaw.Path.forEach(point => {
        let nextPoint = new Point()
        nextPoint = unmarshalPoint(point)
        path.push(nextPoint)
      });

      const lastIndex = path.length - 1

      let lastPoint = path.getPoint(lastIndex)

      const firstIndex = 0
      let firstPoint
      if (lastIndex !== firstIndex) {
        firstPoint = path.getPoint(firstIndex)
      }
      
      setTracker(new Tracker(name, updated, lastPoint, firstPoint, path))
    }
  },[trackerRaw, setTracker])

  function unmarshalPoint(inPoint) {
    let outPoint = new Point()
    outPoint.lon = inPoint.Lon
    outPoint.lat = inPoint.Lat
    outPoint.ele = inPoint.Ele
    outPoint.velocity = inPoint.Velocity
    outPoint.course = inPoint.Course
    outPoint.timestamp = new Date(Date.parse(inPoint.Timestamp))
    return outPoint
  }

  function generateRandomData() {
    if (this.state.setTracker) {
      let point = new Point()
      point.lon = Math.random() -94
      point.lat = Math.random() +45
      point.course = 0
      point.velocity = 10
      point.timestamp = new Date(Date.now())
      this.state.setTracker(new Tracker("random", Date.now(), point))
      console.log(`new point at ${point.lon},${point.lat}`)
    }
  }

  return <Fetcher url={trackerURL} intervalTime={trackerInterval.current} updateTarget={setTrackerRaw} intervalms='60000' />

}