export default class Point {
    lon
    lat
    ele // meters

    dist // kilometers
    grade
    velocity
    course
    timestamp

    // waypoints
    name
    symbol
    routeIndex

    constructor(lon, lat, ele = null) {
        this.lon = lon
        this.lat = lat
        this.ele = ele
    }

    getCoords() {
        return [this.lon, this.lat]
    }
}