import { useRef, useEffect, useState } from "react";

export default function Preferences({map, route, tracker, routeList, updateSelectedRoute, selectedRoute, startTime, updateSelectedStartTime, setResetZoom}) {
    const position = 'bottom-right'
    const myPrefsButton = useRef(null)
    const buttonMapRef = useRef(null)
    const [lastCoords, setLastCoords] = useState([])
    const myRoute = useRef(null)


    useEffect(() => {
        const updateRouteFile = (routeFile) => {
            updateSelectedRoute(routeFile)
        }
        const updateStartTime = (startTime) => {
            updateSelectedStartTime(startTime)
        }
        var newCoords
        if (tracker) {
            newCoords = tracker.lastPoint.getCoords()
        }
        if (route && tracker && routeList && map && (!arraysAreEqual(newCoords,lastCoords) || route !== myRoute.current)) {
            setLastCoords(newCoords)
            myRoute.current = route
            if (!myPrefsButton.current) {
                myPrefsButton.current = new PrefsButton(map, route, tracker, routeList, selectedRoute, updateRouteFile, startTime, updateStartTime, setResetZoom)
                map.on('load', () => {
                    buttonMapRef.current = map.addControl(myPrefsButton.current, position)
                    myPrefsButton.current.update(map, route, tracker, routeList)
                })
            } else {
                // never called? What? How does it even work?
                myPrefsButton.current.update(map, route, tracker, routeList, selectedRoute)
            }
        }
    }, [map, route, tracker, routeList, selectedRoute, updateSelectedRoute, lastCoords, startTime, updateSelectedStartTime])

    return null
}

// There may be floating point precision danger here
function arraysAreEqual(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
}

class PrefsButton {
    routeButton
    trackButton
    routeListButton
    prefsMenuDiv


    route
    tracker
    routeList
    selectedRoute
    updateRouteFile

    setResetZoom

    constructor(map, route, tracker, routeList, selectedRoute, updateRouteFile, startTime, updateStartTime, setResetZoom) {
        this.route = route
        this.tracker = tracker
        this.routeList = routeList
        this.updateRouteFile = updateRouteFile
        this.selectedRoute = selectedRoute
        this.setResetZoom = setResetZoom
        
        this.routeButton = document.createElement("button")
        this.routeButton.innerHTML = `<img src="/icons/route-svgrepo-com.svg" width="18px" height="18px"/>`

        this.trackButton = document.createElement("button")
        this.trackButton.innerHTML = `<img src="/icons/location-arrow-svgrepo-com.svg" width="18px" height="18px"/>`

        this.startTime = startTime
        this.updateStartTime = updateStartTime

        this.routeListButton = document.createElement("button")
        this.routeListButton.classList.add("prefs-menu-button")
        this.routeListButton.innerHTML = this.getRouteListButtonHTML()
    }

    update(map, route, tracker, routeList, selectedRoute) {
        if (map && this.tracker) {
            // Truncates to 10 decimals for floating point precision errors 
            // Considered putting this in the trackerClient but this is where the flyTo for the tracker already exists
            const center = map.getCenter()
            const shortCenter = [center.lng.toFixed(10),center.lat.toFixed(10)]
            const shortTracker = [this.tracker.lastPoint.lon.toFixed(10), this.tracker.lastPoint.lat.toFixed(10)]
            if (arraysAreEqual(shortCenter, shortTracker)) {
                this.flyToTracker(map, tracker)
            }
        }
        this.route = route
        this.tracker = tracker
        this.routeList = routeList
        this.selectedRoute = selectedRoute
        this.updateListeners(map)
    }

    addListeners(map, routeButton, trackButton, routeListButton) {
        routeButton.addEventListener("click", () => {
            map.fitBounds(this.route.bounds, { padding: 40 })
            this.setResetZoom(true)
        })
        trackButton.addEventListener("click", () => this.flyToTracker(map, this.tracker))
        routeListButton.addEventListener("click", () => {this.toggleMinimized()})
        console.log("added prefs listeners")
    }

    updateListeners(map, setResetZoom) {
        var newRouteButton = this.routeButton.cloneNode(true)
        var newTrackButton = this.trackButton.cloneNode(true)
        var newRouteListButton = this.routeListButton.cloneNode(true)
        this.addListeners(map, newRouteButton, newTrackButton, newRouteListButton)
        if (this.routeButton.parentNode) {
            this.routeButton.parentNode.replaceChild(newRouteButton, this.routeButton)
            this.trackButton.parentNode.replaceChild(newTrackButton, this.trackButton)
            this.routeListButton.parentNode.replaceChild(newRouteListButton, this.routeListButton)
        }
        this.routeButton = newRouteButton
        this.trackButton = newTrackButton
        this.routeListButton = newRouteListButton
    }

    onAdd(map) {
        const div = document.createElement("div")

        const div1 = document.createElement("div")
        div1.className = "mapboxgl-ctrl mapboxgl-ctrl-group"
        div1.appendChild(this.routeButton)
        div1.appendChild(this.trackButton)

        const div2 = document.createElement("div")
        div2.className = "mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-compact prefs-menu"
        div2.appendChild(this.getRouteListHTML())
        const routeListButtonHolder = document.createElement("div")
        routeListButtonHolder.className = "button-holder"
        routeListButtonHolder.appendChild(this.routeListButton)
        div2.appendChild(routeListButtonHolder)
        div.appendChild(div1)
        div.appendChild(div2)

        this.prefsMenuDiv = div2
        this.div = div
        return div
    }

    onRemove(map) {
        this.div.remove()
    }

    flyToTracker(map, tracker) {
        const zoomBearing = tracker.lastPoint.course ? tracker.lastPoint.course : 0 // garmin stopped setting course when not moving 20231206
        map.flyTo({center: tracker.lastPoint.getCoords(), bearing: zoomBearing, zoom: 13, pitch: 70})
    }

    toggleMinimized() {
        console.log("'click!'")
        if (this.prefsMenuDiv.classList.contains("mapboxgl-compact-show")) {
            this.prefsMenuDiv.classList.remove("mapboxgl-compact-show")
        } else {
            this.prefsMenuDiv.classList.add("mapboxgl-compact-show")
        }
    }

    getRouteListButtonHTML() {
        return '<img src="/icons/hamburger-menu-svgrepo-com.svg" width="18px" height="18px"/>'
    }

    getRouteListHTML() {
        // TODO: messy
        const prefsDiv = document.createElement("div")
        prefsDiv.className = "prefs-menu-inner"

        const timeSelection = document.createElement("ul")
        prefsDiv.appendChild(timeSelection)
        const timeSelectionHeader = document.createElement("lh")
        timeSelectionHeader.innerText = "Start Time"
        timeSelection.appendChild(timeSelectionHeader)
        const li = document.createElement('li')
        timeSelection.appendChild(li)
        const timeInput = document.createElement("input")
        timeInput.type = "datetime-local"
        timeInput.id = "startTime"
        if (this.startTime) {
            timeInput.value = this.startTime
        }
        timeInput.addEventListener("change", (event) => this.updateStartTime(event.target.value))
        li.appendChild(timeInput)

        const routeSelection = document.createElement("ul")
        prefsDiv.appendChild(routeSelection)
        const routeListHeader = document.createElement("lh")
        routeListHeader.innerText = "Select Route"
        routeSelection.appendChild(routeListHeader)
        for (const routeFile in this.routeList) {
            const li = document.createElement("li")
            li.innerHTML = `<input type="radio" id="${routeFile}" name="route-list"><label for="${routeFile}">${this.routeList[routeFile]}</label>`
            if (routeFile === this.selectedRoute) {
                li.firstChild.checked = true
            }
            // TODO: use change event on form instead?
            li.addEventListener("click", () => {this.updateRouteFile(routeFile)})
            routeSelection.appendChild(li)
        }
        return prefsDiv
    }

}