export default class Styles {
  USGS = {
    'route': {
      'lineColor': '#6cf',
    },
    'map': {
      'version': 8,
      'sources': {
        'raster-tiles': {
          'type': 'raster',
          "tiles": [
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          ],
          attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
          'tileSize': 256,
        }
      },
      'layers': [
        {
          'id': 'simple-tiles',
          'type': 'raster',
          'source': 'raster-tiles',
          'minzoom': 2,
          'maxzoom': 18
        }
      ]
    }
  }
  mapboxStreets = {
    'route': {
      'lineColor': '#00f',
    },
    'map': 'mapbox://styles/mapbox/streets-v12'
  }
  mapboxOutdoors = {
    'map': 'mapbox://styles/mapbox/outdoors-v12'
  }
}