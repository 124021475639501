import React, { useRef, useCallback, useEffect, useState } from 'react';

import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax

import Fetcher from './fetcher'
import RouteClient from './routeClient'
import RouteManager from './routeManager'
import TrackerClient from './trackerClient'
import TrackerManager from './trackerManager'
import WeatherClient from './weatherClient'
import WeatherManager from './weatherManager'
import Units from './units'
import Preferences from './preferences'
import ElevationChart from './chart'

import HashReader from './hashreader'
import Styles from './styles'

mapboxgl.accessToken = 'pk.eyJ1IjoiYmVuenZhbiIsImEiOiJjang2ZWZvYmEwN3duM3lvM255cmwzOTVhIn0.kR3v2UFvvtqQJ35NdNBRug'

export default function App() {
const hashreader = new HashReader()
const styles = new Styles()

const mapContainer = useRef(null)
const map = useRef(null)

const initialLng =-93.25149
const initialLat = 44.97765
const initialZoom = 9

// handles the tracklist (route list) fetching
const [routeList, setRouteList] = useState(null)
const updateRouteList = (newRouteList) => {
  // newRouteList["NoRoute.gpx"] = "No Route"
  setRouteList(newRouteList)
  let route = Object.keys(newRouteList)[0]
  if (hashreader.get('route') && newRouteList[hashreader.get('route')]) {
    route = hashreader.get('route')
  }
  updateSelectedRoute(route)
}

const [startTime, setStartTime] = useState(hashreader.get('startTime'))
const updateStartTime = (newStartTime) => {
  hashreader.set('startTime', newStartTime)
  setStartTime(newStartTime)
}

// handles the route fetching
const [selectedRoute, setSelectedRoute] = useState(null)
const [routeURL, setRouteURL] = useState(null)
const updateSelectedRoute = (newRouteFile) => {
  setSelectedRoute(newRouteFile)
  setRouteURL(`/tracks/${newRouteFile}`)
  hashreader.set('route', newRouteFile)
}
const [route, setRoute] = useState(null)

// handles the tracker fetching
const trackerURL = '/trackers/progress.json'
const [tracker, setTracker] = useState(null)
const updateTracker = useCallback((newTracker) => {
  setTracker(newTracker)
}, [])

// handles the current tracker location on the route point list
const [trackerIndex, setTrackerIndex] = useState(0)

// handles the weather fetching
const weatherURL = './weather'
const [weather, setWeather] =useState(null)

// handles interface beween map and chart...maybe eventually
const [routeActivatePointFunction, setRouteActivatePointFunction] = useState(null)
const [routeDeactivatePointFunction, setRouteDeactivatePointFunction] = useState(null)

// Default units are english (all data is stored in metric)
const [units, setUnits]  = useState(new Units('mixed'))

const [resetZoom, setResetZoom] = useState(false)
const zoomReset = () => {
  setResetZoom(false)
}

function ShowChart({ units, route, trackerIndex, trackerName, className, resetZoom, zoomReset })  {
  if (route?.length > 0) {
    return (
      <div className='elevation-wrapper'>
      <ElevationChart className="elevation-container" route={route} trackerIndex={trackerIndex} trackerName={tracker?.name} units={units} resetZoom={resetZoom} zoomReset={zoomReset} routeActivatePointFunction={routeActivatePointFunction} routeDeactivatePointFunction={routeDeactivatePointFunction}/>
      </div>
      )
    } else {
      return null
    }
}

  useEffect(() => {
    if (!map.current) { // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: styles.mapboxOutdoors.map,
        center: [initialLng, initialLat],
        zoom: initialZoom
      })
      map.current.on('load', () => {
        map.current.addSource('mapbox-dem', {
          'type': 'raster-dem',
          'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
          'tileSize': 512,
          'maxzoom': 14
        });
        // add the DEM source as a terrain layer with exaggerated height
        map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 })
        map.current.addControl(new mapboxgl.NavigationControl({ visualizePitch: true }))
      })
    }
  })

  return (
    <>
      <div ref={mapContainer} className="map-container" />
      <ShowChart route={route} trackerIndex={trackerIndex} trackerName={tracker?.name} units={units} resetZoom={resetZoom} zoomReset={zoomReset} routeActivatePointFunction={routeActivatePointFunction} routeDeactivatePointFunction={routeDeactivatePointFunction}/>
      <Fetcher url='./tracklist' updateTarget={updateRouteList} />
      
      <RouteClient routeURL={routeURL} setRoute={setRoute}/>
      <RouteManager map={map.current} route={route} units={units} trackerIndex={trackerIndex} setRouteActivatePointFunction={setRouteActivatePointFunction} setRouteDeactivatePointFunction={setRouteDeactivatePointFunction}/>

      <TrackerClient setTracker={updateTracker} trackerURL={trackerURL}/>
      <TrackerManager map={map.current} route={route} tracker={tracker} units={units} setTrackerIndex={setTrackerIndex} startTime={startTime}/>

      <WeatherClient setWeather={setWeather} weatherURL={weatherURL} tracker={tracker}/>
      <WeatherManager map={map.current} weather={weather} units={units}/>

      <Preferences map={map.current} routeList={routeList} route={route} tracker={tracker} selectedRoute={selectedRoute} updateSelectedRoute={updateSelectedRoute} startTime={startTime} updateSelectedStartTime={updateStartTime} setResetZoom={setResetZoom}/>
    </>
  );
}