export default class Units {
    units = {
        english: {
            distance: {
                name: 'miles',
                speedName: 'mph',
                factor: .621371,
            },
            elevation: {
                name: 'feet',
                factor: 3.28084,
            },
            temperature: {
                name: 'F',
                factor: function(c) {return c * 9 / 5 + 32}
            }
        },
        metric: {
            distance: {
                name: 'kilometers',
                speedName: 'kph',
                factor: 1,
            },
            elevation: {
                name: 'meters',
                factor: 1, 
            },
            temperature: {
                name: 'C',
                factor: function(c) {return c}
            }
        },
    }

    mixed = {
        distance: this.units.english.distance,
        elevation: this.units.english.elevation,
        temperature: this.units.metric.temperature,
    }

    setting = this.units.metric

    constructor(setting) {
        this.units["mixed"] = this.mixed
        switch (setting) {
            case 'english':
                this.setting = this.units.english
            break
            case 'mixed':
                this.setting = this.units.mixed
            break
            default:
                this.setting = this.units.metric
        }
    }

    getDistance(metric) {
        return metric * this.setting.distance.factor
    }

    getDistanceName() {
        return this.setting.distance.name
    }

    getSpeedName() {
        return this.setting.distance.speedName
    }

    getElevation(metric) {
        return metric * this.setting.elevation.factor
    }

    getElevationName() {
        return this.setting.elevation.name
    }

    getTemperature(metric) {
        return this.setting.temperature.factor(metric)
    }

    getTemperatureName() {
        return this.setting.temperature.name
    }
}