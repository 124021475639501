import {useRef, useEffect} from 'react';

export default function Fetcher({url, updateTarget, intervalms}) {
    const myURL = useRef(null)
    const lastJson = useRef(null)

    useEffect(() => {
        
        const run = (force) => {
            if ((url && myURL.current !== url) || force === true) {
                myURL.current = url
                fetchRaw()
                console.log(`fetched: ${myURL.current}`)
            } else {
                console.log(`not fetched: ${myURL.current}`)
            }
        }

        const fetchRaw = () => {
            fetch(myURL.current)
            .then(response => {
                if (response.ok) {
                return response.json()
                } else {
                    console.log(`received non-200 response fetching '${myURL.current}'`)
                    return {}
                }
            })
            .then(json => {
                if (json !== lastJson.current) {
                    lastJson.current = json
                    updateTarget(json)
                }
            })
            .catch(e => {
                console.log(`Failed to fetch '${myURL.current}' with error: ${e}`)
            })
        }
        
        run()
        if (intervalms > 0) {
            const intervalTimer = setInterval(() => run(true), intervalms)
            return () => clearInterval(intervalTimer)
        }
    }, [url, updateTarget, intervalms])

    return null
}