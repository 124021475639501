export default class Tracker {
    name
    updated
    lastPoint
    firstPoint
    path

    constructor(name, updated, lastPoint, firstPoint, path) {
        this.name = name
        this.updated = updated
        this.lastPoint = lastPoint
        this.firstPoint = firstPoint
        this.path = path
    }
}