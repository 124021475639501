export default class HashReader {
    constructor() {
        this.hashmap = new Map()
        this.getHashMap()
    }

    getHashMap() {
        let hash = window.location.hash.split('#')[1]
        if (hash?.length > 0) {
            for (let hashSegment of hash.split('&')) {
                if (hashSegment.includes('=')) {
                    let [k,v] = hashSegment.split('=')
                    this.hashmap.set(k,v)
                }
            }
        }
        return this.hashmap
    }

    setHashMap() {
        let hasharray = []
        for (let pair of this.hashmap) {
            hasharray.push(pair.join('='))
        }
        let hashstring = ''
        if (hasharray.length > 1) {
            hashstring = hasharray.join('&')
        } else if (hasharray.length > 0) {
            hashstring = hasharray[0]
        }
        if (hashstring.length > 0) {
            window.location.hash = `#${hashstring}`
        }
    }

    set(k, v) {
        this.hashmap.set(k, v)
        this.setHashMap()
    }

    get(k) {
        return this.hashmap.get(k)
    }
}