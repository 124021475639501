export class Weather {
    name
    id
    point

    observation
    forecastLink

    constructor (name, id, point, observation, forecastLink) {
        this.name = name
        this.id = id
        this.point = point
        this.observation = observation
        this.forecastLink = forecastLink
    }
}

export class Observation {
    timestamp
    textDescription
    temperatureC
    windDirectionDeg
    windSpeedKPH
    windGustKPH
    relativeHumidityPct
    windChillC
    heatIndexC
}