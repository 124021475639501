import { useState, useEffect } from 'react';
import Point from './point'
import Route from './route'
import Fetcher from './fetcher'
import * as turf from '@turf/turf'

export default function RouteParser({ setRoute, routeURL }) {
  const [routeRaw, setRouteRaw] = useState()

  useEffect(() => {
    if (setRoute && routeRaw) {

      let route = new Route()
      let points = []
      if (routeRaw && routeRaw.Path && routeRaw.Path.length > 0) {
        for (let jpt of routeRaw.Path) {
          let point = new Point()
          point.lat = jpt.Lat
          point.lon = jpt.Lon
          point.ele = jpt.Ele
          point.dist = jpt.Dist ? jpt.Dist / 1000 : 0 // source distance is meters
          point.grade = jpt.Grade ? jpt.Grade : 0
          points.push(point)
        }
      }
      route.path.points = points
      route.setBounds()

      if (routeRaw.Waypoints) {
        const line = turf.lineString(route.getCoords())
        let waypoints = []

        for (let jpt of routeRaw.Waypoints) {
          let point = new Point()
          point.lat = jpt.Lat
          point.lon = jpt.Lon
          point.name = jpt.Name
          point.symbol = jpt.Symbol

          const origin = turf.point(point.getCoords())
          const match = turf.nearestPointOnLine(line, origin)
          point.routeIndex = match.properties.index

          point.dist = route.getPoint(point.routeIndex).dist
          point.ele = jpt.Ele ? jpt.Ele : route.getPoint(point.routeIndex).ele

          waypoints.push(point)
        }
        waypoints.sort((a, b) => { return a.dist - b.dist })
        route.waypoints = waypoints
      }

      setRoute(route)
    }

  }, [routeRaw, setRoute])

  return <Fetcher url={routeURL} updateTarget={setRouteRaw} />

}