import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

export default class Path {
  points

  constructor() {
    this.points = []
  }

  getCoords = () => {
    if (this.length < 1) {
      console.log("no points in path!!")
    }
    let coords = []
    this.points.forEach(function (point) {
      coords.push(point.getCoords())
    })
    return coords
  }

  getPoint = (index) => {
    return this.points[index]
  }

  getGeoJSON = () => {
    let geojson = {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'LineString',
          'coordinates': this.getCoords()
        }
      }
    }
    return geojson
  }

  get length() {
    return this.points.length
  }

  getBounds = () => {
    var bounds = new mapboxgl.LngLatBounds()
    this.points.forEach(function (point) {
      bounds.extend(point.getCoords())
    })
    return bounds
  }

  push = (point) => {
    this.points.push(point)
  }
}