import { useEffect, useRef } from 'react';
import { Marker, Popup } from 'mapbox-gl'

export default function RouteManager({ route, map, units, trackerIndex }) {
  const myUnits = useRef(units)
  const start = useRef(new Marker({ color: "#0F0" }))
  const end = useRef(new Marker({ color: "#F00" }))
  const myRoute = useRef(null)
  const tooltip = useRef(new Popup({}))

  const routeLineSourceRef = useRef(null)
  const routeLineLayerRef = useRef(null)
  const routePointsSourceRef = useRef(null)
  const routePointsLayerRef = useRef(null)
  const routeWaypointsSourceRef = useRef(null)
  const routeWaypointsLayerRef = useRef(null)

  const myTrackerIndex = useRef(0)

  const initialized = useRef(false)

  const lightTrackColor = '#6cf'
  const darkTrackColor = '#00f'

  // Active point on route for hover effects
  var activePointID
  var activeWaypointID

  useEffect(() => {
    function addWaypoints(route, map) {
      routeWaypointsSourceRef.current = map.addSource('route-waypoints', route.getFeatureGeoJson(route.getWaypointsFeatureList()))
      routeWaypointsLayerRef.current = map.addLayer({
        id: 'route-waypoints',
        type: 'circle',
        source: 'route-waypoints',
        paint: {
          'circle-radius': 4,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#55f',
          'circle-color': '#fff'
        }
      })
      map.on('mousemove', 'route-waypoints', (event) => {
        map.getCanvas().style.cursor = 'pointer'
        if (event.features.length === 0) return
        activateWaypoint('route-waypoints', event.features[0].id, map, myRoute, myUnits)
      })

      map.on('mouseleave', 'route-waypoints', () => {
        deactivateWaypoint('route-waypoints', map)
      })
    }

    if (map && route && route.length > 1 && myRoute.current !== route) {
      start.current.setLngLat(route.getPoint(0).getCoords())
      end.current.setLngLat(route.getPoint(route.length - 1).getCoords())
      myRoute.current = route


      if (initialized.current === false) {
        // onload serves as a blocker until the map is present. Is there a better way where these layers can be more dynamic?
        map.on('load', () => {
          start.current.addTo(map)
          end.current.addTo(map)

          routeLineSourceRef.current = map.addSource('route', route.getGeoJSON())
          routeLineLayerRef.current = map.addLayer({
            'id': 'route',
            'type': 'line',
            'source': 'route',
            'layout': {
              'line-join': 'round',
              'line-cap': 'round'
            },
            'paint': {
              'line-color': darkTrackColor,
              'line-width': 4
            }
          })

          routePointsSourceRef.current = map.addSource('route-points', route.getFeatureGeoJson(route.getRouteFeatureList()))
          routePointsLayerRef.current = map.addLayer({
            id: 'route-points',
            type: 'circle',
            source: 'route-points',
            paint: {
              'circle-stroke-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0
              ],
              'circle-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0
              ],
              'circle-radius': 8,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#55f',
              'circle-color': '#fff'
            }
          })

          map.on('mousemove', 'route-points', (event) => {
            map.getCanvas().style.cursor = 'pointer'
            if (event.features.length === 0) return

            activatePoint('route-points', event.features[0].id, map, myRoute, myUnits)
          })

          map.on('mouseleave', 'route-points', () => {
            deactivatePoint('route-points', map)
          })

          // Waypoints must be added after route points to keep them on top for mouseover detection
          if (route.waypoints) {
            addWaypoints(route, map)
          }

          zoomTo(map, route)
          initialized.current = true

        })
      } else { // initialized === true
          map.getSource('route').setData(route.getGeoJSON().data)
          map.getSource('route-points').setData(route.getFeatureGeoJson(route.getRouteFeatureList()).data)
          
          if (route.waypoints) {
            if (map.getSource('route-waypoints')) {
              map.getSource('route-waypoints').setData(route.getFeatureGeoJson(route.getWaypointsFeatureList()).data)
            } else {
              addWaypoints(route, map)
            }
          } else {
            if (map.getLayer('route-waypoints')) {
              map.removeLayer('route-waypoints')
            }
            if (map.getSource('route-waypoints')) {
              map.removeSource('route-waypoints')
            }
          }
          zoomTo(map,route)
      }

    } else if (route && route.length === 0) {
      // this is where we would clear the map if no route is selected.
    }

  }, [map, route, trackerIndex])

  useEffect(() => {
    myTrackerIndex.current = trackerIndex
  }, [trackerIndex])

  function setActivePoint(value) {
    activePointID = value
    // TODO this is super slow
    // this.setHoverPoint(value)
  }

  function setActiveWaypoint(value) {
    activeWaypointID = value
  }

  function zoomTo(map, route) {
    map.fitBounds(route.bounds, { padding: 40 })
  }

  function activateWaypoint(source, featureID, map, route, units) {
    if (activeWaypointID) {
      map.removeFeatureState({
        source: source,
        id: activeWaypointID
      })
    }
    setActiveWaypoint(featureID)
    const activeWaypoint = route.current.waypoints[featureID]
    map.setFeatureState({
      source: source,
      id: activeWaypointID,
    },
      {
        hover: true,
      })
    tooltip.current.setLngLat(activeWaypoint.getCoords()).setHTML(getWaypointTooltipText(route.current, units.current, myTrackerIndex.current, activeWaypoint)).addTo(map)
  }
  
  function getWaypointTooltipText(route, units, trackerIndex, waypoint) {
    var innerText = `<strong>${waypoint.name}</strong><br/>`
    innerText += `<strong>Distance:</strong> ${units.getDistance(route.getPoint(waypoint.routeIndex).dist).toFixed(2)}<br/>`
    innerText += `<strong>Type:</strong> ${waypoint.symbol}`
    return innerText
  }

  function activatePoint(source, featureID, map, route, units) {
    if (activePointID) {
      map.removeFeatureState({
        source: source,
        id: activePointID,
      })
    }

    setActivePoint(featureID)
    const activePoint = route.current.getPoint(featureID)
    map.setFeatureState({
      source: source,
      id: activePointID,
    },
      {
        hover: true
      })
    tooltip.current.setLngLat(activePoint.getCoords()).setHTML(getTooltipText(route.current, units.current, myTrackerIndex.current)).addTo(map)
  }

  function deactivatePoint(source, map) {
    if (activePointID) {
      map.setFeatureState({
        source: source,
        id: activePointID
      },
        {
          hover: false
        })
    }
    setActivePoint(null)
    tooltip.current.remove()
  }

  function deactivateWaypoint(source, map) {
    if (activeWaypointID) {
      map.setFeatureState({
        source: source,
        id: activeWaypointID
      },
        {
          hover: false
        })
    }
    setActiveWaypoint(null)
    tooltip.current.remove()
  }

  function getTooltipText(route, units, trackerIndex) {
    const activePoint = route.getPoint(activePointID)
    const trackerDiff = activePoint.dist - route.getPoint(trackerIndex).dist
    var innerText = `<strong>Distance:</strong> ${units.getDistance(activePoint.dist).toFixed(2)}<br/>`
    innerText += `<strong>Grade:</strong> ${activePoint.grade.toFixed(2)}<br/>`
    // TODO This kind of breaks having more than one tracker but might be nice to have.
    innerText += `${units.getDistance(Math.abs(trackerDiff)).toFixed(2)} ${trackerDiff > 0 ? "to go" : "since"}<br/>`
    return innerText
  }

  return null
}